.work-experience-card{
    background: rgba(54, 37, 6, 0.698);
    border-radius: 0.65rem;
    border: 1.5px solid #ce8a0b;
    padding: 1.5rem;
    margin: 0 1rem;
}

.work-experience-card h6{
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.duration{
    display: inline-block;
    font-size: 0.7rem;
    font-weight: 400;
    background: #4b3409;
    border-radius: 0.3rem;
    padding: 0.4rem 0.6rem;
    margin-bottom: 1rem;
}

.work-experience-card ul li {
    list-style: none;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    position: relative;
}

.work-experience-card ul li::after{
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background-color: orange;
    border-radius:0.5rem;
    position: absolute;
    left: -1rem;
    top: 0.5rem;
}

@media (max-width: 1025px) {
    .work-experience-card{
        margin: 1;
    }

    .work-experience-card h6{
        font-size: 1rem;
    }

    .work-experience-card ul li{
        font-size: 0.8rem;
    }

    .duration{
        font-size: 0.6rem;
    }
}