@import url('https://fonts.googleapis.com/css2?family=Madimi+One&family=Micro+5&family=Oswald:wght@200..700&family=Salsa&display=swap');

*{
  font-family: Oswald, sans-serif;
  margin: 0;
  box-sizing: border-box;
  color: white
}

body{
  background: black;
}

.container{
  max-width: 1300px !important;
  margin: 0 auto;
  position:relative;
}

@media (max-width: 1300px){
  .container{
    padding: 0 1.5rem;
  }
}