.main-container{
    display: flex;
    align-items: center;
    position: relative;
}

.main-container::after,
.main-container::before{
    content: '';
    width: 30rem;
    height: 30rem;
    border-radius: 30rem;
    position: absolute;
    background: orange;
    z-index: -1;
    filter:blur(225px);
}

.main-container::after{
    top: -3rem;
    left: -5rem;
}

.main-container::before{
    bottom: 2rem;
    right: 0rem;
}

.main-content{
    flex: 1;
}

.main-content h2{
    font-size: 3.8rem;
    font-weight: 600;
    line-height: 5rem;
    margin-bottom: 1rem;
}

.main-content p{
    width: 80%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem; 
}

.main-image{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;   
    gap: 2rem;
    margin-top: 5rem; 
}

.main-image > div{
    display:flex;
    align-items: flex-end;
    gap: 2rem;
    
}

.main-image > div > img{
    width: 25rem;
    transition: all 0.3s ease;
    border-radius: 0.65rem;
}

.main-icon{
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 0.65rem;
    border: 1.5px solid orange;
    background: white;
    opacity: 0.6;
}

.main-icon img{
    width: 3rem;
    height: 3rem;
    transition: all 0.3s ease;
}

.main-image > div > img:hover,
.main-icon img:hover{
    transform: translateY(-0.5rem);
}


@media (max-width: 1025px) {
    .main-content h2{
        font-size: 3rem;
        line-height: 4rem;
    }

    .main-content p{
        font-size: 0.9rem;
        line-height: 1.6rem;
    }

    .main-image > div > img{
        width: 20rem;
    }
    
    .main-icon{
        width: 4rem;
        height: 4rem;
    }

    .main-icon img{
        width: 2.5rem;
        
    }
}

@media (max-width: 768px) {

    .main-container{
        flex-direction: column;
    }

    .main-image{
        margin-top:3rem;
    }

    .main-content h2,
    .main-content p{
        width: auto;
        text-align: center;
    }

    .main-container::after,
    .main-container::before{
        content: " ";
        width: 18rem;
        height: 18rem;
    }

    .main-container::after{
        top: 0rem;
        left: 0rem;
    }

    .main-container::before{
        bottom: 0rem;
        right: 0rem;
    }
}

@media (max-width: 600px){

    .main-content h2{
        font-size: 2.2rem;
        line-height: 3rem;
    }

    .main-content p{
        font-size:0.8rem;
        line-height: 1.3rem;
    }

    .main-image{
        gap: 1rem;
        margin: 1rem;
    }

    .main-image > div > img{
        width: 15rem;
    }

    .main-icon{
        width: 3.5rem;
        height: 3.5rem;
    }

    .main-icon img{
        width: 2rem;
    }
}

@media (max-width: 320px){
    .main-image > div > img{
        width: 12rem;
    }
}