.contact-form-content {
    width: 100%;
}

form {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
}

.name-container{
    display:flex;
    align-items: center;
    grid-gap: 1.5rem;
}

form input,
form textarea {
    flex: 1;
    width: 100%;
    font-size:0.9rem;
    color: #fff;
    background: rgba(54, 37, 6, 0.698);
    border: 1.5px solid orange;
    border-radius: 0.3rem;
    padding: 1rem;
}

form button {
    font-size: 1rem;
    font-weight: 500;
    background: linear-gradient(180deg, rgba(206, 138, 11, 0.74) 0%, rgba(54, 37, 6, 0.698) 100%);
    border: 1.5px solid orange;
    border-radius: 0.3rem;
    padding: 1rem;
}

form button:hover {
    background: rgba(255, 166, 0, 0.479);
    border: 1.5px solid orange;
    color: #fff;
}

@media (max-width: 450px) {
    .name-container{
        flex-direction: column;
        align-items: flex-start;
    }

    form input,
    form textarea{
        padding:0.7rem;
        border-radius: 0.3rem;
    }

    form button{
        font-size: 0.9rem;
        padding: 0.7rem;
        border-radius: 0.3rem;
    }
}