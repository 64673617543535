.nav-wrap{
    background: rgba(0,0 ,0 , 0.1);
    padding:0.5rem 0;
    position: sticky;
    top:0;
    z-index: 30;
    backdrop-filter: blur(40px);
}

.nav-cont{
    max-width: 1300px;
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 auto;
}

.logo{
    width: 12rem;
    height: auto;
}

.nav-cont ul{
    display:flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;

}

.nav-cont li {
    margin: 0 1.5rem;
}

.menu-item{
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
    position: relative;
    cursor:pointer;
}

.menu-item::before{
    content: " ";
    width: 2rem;
    height: 0.2rem;
    background: linear-gradient(90deg, #FFC107, #FF9800);
    border-radius: 0.5rem;
    position:absolute;
    bottom: -0.5rem;
    opacity:0;
    transform: translateX(-1.5rem);
    transition: all 0.3s ease;
}

.menu-item:hover::before{
    width: 100%;
    opacity:1;
    transform: translateX(0);
}

.contact-btn {
    font-size:0.9rem;
    font-weight: 500;
    display:flex;
    align-items:center;
    justify-content: center;
    color: white;
    background: linear-gradient(90deg, #FFC107, #FF9800);
    padding: 0.5rem 1rem;
    border:none;
    outline: 1.5px solid transparent;
    border-radius: 0.5rem;
    cursor:pointer;
    transition: all 0.3s ease;
}

.contact-btn:hover{
    background: transparent;
    color: white;
    outline: 1.5px solid #FFC107;
}

.menu-btn{
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    color: white;
    background: linear-gradient(90deg, #FFC107, #FF9800);
    cursor:pointer;
    transition: all 0.3s ease;
    display: none;
}

.menu-btn:hover{
    background: #FF9800;
    color: white;
    outline: 1.5px solid #FFC107;
}

@media (max-width: 425px){
    .logo{
        width: 10rem;
        height:auto;
    }
}

@media (max-width: 769px){

    .menu-btn{
        display: block;
    }

    .nav-cont ul{
        display: none;
    }
}

@media (max-width: 1325px){
    .nav-wrap{
        padding: 0 2rem;
    }
}