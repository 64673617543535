.Projects-container{
    display: flex;
    align-items: center;
    position: relative;
}

.Project-content h1{
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Projects-container::after,
.Projects-container::before{
    content: '';
    width: 30rem;
    height: 30rem;
    border-radius: 30rem;
    position: absolute;
    background: orange;
    z-index: -1;
    filter:blur(225px);
}

.Projects-container::after{
    top: -3rem;
    left: -5rem;
}

.Projects-container::before{
    bottom: -3rem;
    right: -5rem;
}

.Projects{
    max-width: 1300px;
    margin: 1rem auto;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.Projects h3{
    font-size: 2rem;
    font-weight: 500;
    color: #FF9800;
    text-align: center;
    margin-bottom: 1rem;
}

.Projects img{
    flex:1;
    width: 60%;
    height: auto;
}

.project-info {
    border: 1px solid #FF9800;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    padding: 2rem;
}
  
.project-info p {
    margin-left: 1rem; 
}

.plans{
    margin-top: 1rem;
}

@media (max-width: 425px){
    .Projects h3{
        font-size: 1.5rem;
    }
    .Projects img{
        width: 100%;
    }
    .project-info{
        flex-direction: column;
    }
    .project-info p{
        margin-left: 0;
        margin-top: 1rem;
    }

    .Projects-container::after,
    .Projects-container::before{
        width: 20rem;
        height: 20rem;
    }
}