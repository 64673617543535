.contact-details-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(54, 37, 6, 0.698);
    border-radius: 0.5rem;
    border: 1px solid orange;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.contact-details-card .icon{
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    background-color: rgba(255, 166, 0, 0.712);
    margin-bottom: 1rem;
}

.icon img {
    width: 3.5rem;
    height: auto;
    object-fit: contain;
}

.contact-details-card p {
    font-size: 0.9rem;
    font-weight: 400;
}